<template>
 <div class="hpgrid-max-width ma-auto" data-chain-name="diversions wrapper" data-gtm-module="diversions wrapper" style="position: relative">
                <div class="mr-sm ml-sm mr-0-ns ml-0-ns">
                    <div class="gray-darkest mb-sm label label-section-large left">
                        <a class="
                  font-xs font-bold
                  uppercase
                  letter-spacing
                  offblack
                  hover-blue
                  label-link
                " data-href="#https://games.washingtonpost.com/"><span>Diversions</span><span class="relative" style="margin-left: 0.125rem"><span style="position: absolute; size-to-use: normal; top: 0"><svg class="
                        content-box
                        fill-gray
                        hover-fill-blue hover-inherit
                      " width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-sc-v="5.1.1" data-sc-c="iconbase">
                                        <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                            ChevronRight
                                        </title>
                                        <path d="M9.44 7.854L4.145 2.56l1.415-1.415 6 6a1 1 0 0 1 0 1.415l-6 6-1.415-1.415L9.44 7.854z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                    </svg></span></span></a>
                    </div>
                </div>
                <div class="
              chain
              hpgrid hpgrid-max-width
              ma-auto
              mx-dsktp-order
              large-bottom-separator
              no-line-bottom
              include-dividers-tables
            ">
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                full-span-layout
                grid-top grid-bottom grid-left grid-right
                allcontent
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 1;
                --c-start-lg: 1;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 20;
                --c-span-lg: 16;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  allcontent-childStyles
                  grid-top grid-bottom grid-left grid-right
                  div-wrap
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 1;
                  --c-start-md: 1;
                  --c-start-sm: 1;
                  --c-start-xs: 1;
                  --c-span-mx: 20;
                  --c-span-lg: 16;
                  --c-span-md: 12;
                  --c-span-sm: 10;
                  --c-span-xs: 1;
                  --dsktp-order: 0;
                " data-index="0" data-admin-id="1" data-feature-name="frontsdiversions" moat-id="fronts/diversions" data-mx-span="20" data-lg-span="16" data-md-span="12" data-sm-span="10" data-xs-span="1">
                            <div class="gray-darkest diversions">
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://www.washingtonpost.com/entertainment/comics/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Comics" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                CommentSolid
                                            </title>
                                            <path d="M7 11l-3 3v-3H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H7z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Comics</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://www.washingtonpost.com/crossword-puzzles/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Crosswords" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Crossword
                                            </title>
                                            <path d="M3 14a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h3V2a1 1 0 0 1 1-1h3c.183 0 .354.049.501.134A.982.982 0 0 1 11 1h3a1 1 0 0 1 1 1v3a.995.995 0 0 1-.134.501A.982.982 0 0 1 15 6v3a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3zm3-4H3v3h3v-3zm0-4H3v3h3V6zm4 0H7v3h3V6zm4 0h-3v3h3V6zm-4-4H7v3h3V2z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Crosswords</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#http://games.washingtonpost.com/games/mahjongg-dark-dimensions/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Mah Jongg" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Mahjongg
                                            </title>
                                            <path d="M2 5.408l6 3.488v6.079l-6-3.599V5.408zm12.8.028v5.94L9 14.854V8.808l5.8-3.372zm-6.491-4.41l6.012 3.657-5.988 3.535L2.32 4.67 8.31 1.025z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Mah Jongg</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://games.washingtonpost.com/games/daily-sudoku/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Sudoku" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Sudoku
                                            </title>
                                            <path d="M14 1a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h11zM3 10v3h3v-3H3zm7 0H7v3h3v-3zm4 0h-3v3h3v-3zM7 5.999V9h3V6l-3-.001zM14 6h-3v3h3V6zm0-4h-3v3h3V2zm-4 0H7v2.999L10 5V2zM6 2H3v3l3-.001V2zM3 9h3V5.999L3 6v3z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Sudoku</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://games.washingtonpost.com/games/word-wipe/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Wordwipe" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Wordwipe
                                            </title>
                                            <path d="M12 1a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3h8zM4.845 4H3l2.056 8h1.728L7.9 7.526l.118-.504.129.504L9.31 12h1.669L13 4h-1.528l-1.08 4.69-.142.515-.117-.515L8.899 4H7.37L6.243 8.678l-.14.527-.13-.551L4.845 4z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Wordwipe</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://www.washingtonpost.com/entertainment/horoscopes/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Horoscopes" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Horoscopes
                                            </title>
                                            <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm3.51 7.788a.6.6 0 0 1-.867-.83c.346-.361.533-.659.533-1.195 0-.354-.314-1.085-1.289-1.085-.829 0-1.287 1.256-1.287 2.123v3.585a.6.6 0 0 1-1.2 0V7.8c0-.867-.459-2.123-1.288-2.123-.974 0-1.29.73-1.29 1.085 0 .536.188.833.534 1.195a.6.6 0 0 1-.867.83c-.429-.449-.867-1.04-.867-2.025 0-1.099.952-2.285 2.49-2.285.82 0 1.46.474 1.888 1.156.428-.682 1.068-1.156 1.887-1.156 1.538 0 2.49 1.186 2.49 2.285 0 .986-.439 1.577-.867 2.025z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Horoscopes</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#http://games.washingtonpost.com/games/klondike-solitaire/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Solitaire" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Solitaire
                                            </title>
                                            <path d="M12.269 5.466C11.433 4.642 8.672 2.568 7.96 1h-.24C7.032 2.565 4.38 4.62 3.57 5.453c-.81.833-1.492 1.404-1.492 3.007 0 1.957 1.166 3.126 3.115 3.126 1.205 0 1.818-.884 2.182-1.698-.24 2.135-.655 4.688-1.897 4.688V15h4.966v-.356c-1.242 0-1.7-2.621-1.941-4.756.364.815.925 1.698 2.13 1.698 1.95 0 3.118-1.17 3.118-3.126 0-1.603-.646-2.17-1.482-2.994z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>Solitaire</div>
                                    </a>
                                </div>
                                <div>
                                    <a class="inline-flex items-center black pr-lgmod pointer" data-href="#https://www.washingtonpost.com/entertainment/tv/" style="white-space: nowrap"><svg class="content-box gray-darkest mr-xxs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="TV Listing" data-sc-v="5.1.1" data-sc-c="iconbase">
                                            <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                                Tvlistings
                                            </title>
                                            <path d="M10.113.561l.713.702-2.699 2.736L13 4c.552 0 1 .504 1 1.125v6.75c0 .621-.448 1.125-1 1.125H9v1h4v1H3v-1h4v-1H3c-.552 0-1-.504-1-1.125v-6.75C2 4.504 2.448 4 3 4l3.752-.001-1.784-1.784.707-.707L7.44 3.273 10.113.561zM13 5H3v7h10V5zm-1 1v5H4V6h8z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                        </svg>
                                        <div>TV Listing</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import axios from "axios";


export default {
    props: ["categoryNewsData","keyVal"],
    components: {
        carousel,
        // starRating,
    },
    data() {
        return {
            whishlist: 0,
        };
    },
    mounted() {
        this.orderDetail()
    },
    methods: {
          orderDetail() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'myorder',
                    uid: ev.uid,

                }
            }).then(function (response) {
                var data = response.data.data
                ev.orderDetailData = data
            })
        },
    },
};
</script>
