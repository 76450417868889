<template>
 <div class="hpgrid-max-width ma-auto" data-chain-name="food" data-gtm-module="food" style="position: relative">
                <div class="mr-sm ml-sm mr-0-ns ml-0-ns">
                    <div class="gray-darkest mb-sm label label-section-large left">
                        <a class="font-xs font-bold uppercase letter-spacing offblack hover-blue label-link" href="#"><span>Food</span>
                        <span class="relative" style="margin-left: 0.125rem">
                          <span style="position: absolute; size-to-use: normal; top: -3px;">
                                <svg class="content-box fill-gray hover-fill-blue hover-inherit" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-sc-v="5.1.1" data-sc-c="iconbase">
                                    <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                        ChevronRight
                                    </title>
                                    <path d="M9.44 7.854L4.145 2.56l1.415-1.415 6 6a1 1 0 0 1 0 1.415l-6 6-1.415-1.415L9.44 7.854z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                </svg>
                            </span>
                          </span>
                        </a>
                    </div>
                </div>
                <div class="chain hpgrid hpgrid-max-width ma-auto mx-dsktp-order large-bottom-separator no-line-bottom include-dividers-tables">
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                double-wide-with-far-right-table-layout
                grid-top grid-bottom grid-left
                table1
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 1;
                --c-start-lg: 1;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 8;
                --c-span-lg: 8;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="card relative hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans table1-columns-main grid-top grid-bottom grid-left" style="
                  --c-start-mx: 1;
                  --c-start-lg: 1;
                  --c-start-md: 1;
                  --c-start-sm: 1;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 4;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --r-span-mx: 4;
                  --r-span-lg: 4;
                  --r-span-md: 4;
                  --r-span-sm: 4;
                  --dsktp-order: 3;
                " data-index="0" data-admin-id="1" data-feature-name="food-feed-lede" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="4" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left no-wrap-text art-size--fullWidth">
                                <div class="card-top card-text">
                                    <div class="w-100 mw-100 overflow-hidden relative lh-0 mb-xs">
                                        <div class="dib" style="
                          width: 100%;
                          height: 100%;
                          background-color: #eee;
                        ">
                                            <a data-href="#https://www.washingtonpost.com/health/2022/01/13/fda-deregulates-french-dressing/" class="art-link"><img style="
                              width: 232px;
                              height: 130px;
                              background-color: #eee;
                            " src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Fimg%252Ewashingtonpost%252Ecom%2Fwp-stat%2Fspacer%252Egif&amp;w=232&amp;h=130" alt="" width="232" height="130" class="border-box db" data-placeholder="true" /></a>
                                        </div>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/health/2022/01/13/fda-deregulates-french-dressing/"><span>After 72 years, FDA says French dressing won’t have
                                                    a legal definition</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/amy-cheng/">Amy Cheng</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table1-columns-right
                  grid-top grid-bottom grid-right
                " style="
                  --c-start-mx: 5;
                  --c-start-lg: 5;
                  --c-start-md: 7;
                  --c-start-sm: 6;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 4;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --r-span-mx: 4;
                  --r-span-lg: 4;
                  --r-span-md: 4;
                  --r-span-sm: 4;
                  --dsktp-order: 4;
                " data-index="1" data-admin-id="2" data-feature-name="food-feed-2" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="4" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left no-wrap-text art-size--fullWidth">
                                <div class="card-top card-text">
                                    <div class="w-100 mw-100 overflow-hidden relative lh-0 mb-xs">
                                        <div class="dib" style="
                          width: 100%;
                          height: 100%;
                          background-color: #eee;
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/recipes-for-one-or-two/" class="art-link"><img style="
                              width: 232px;
                              height: 130px;
                              background-color: #eee;
                            " src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Fimg%252Ewashingtonpost%252Ecom%2Fwp-stat%2Fspacer%252Egif&amp;w=232&amp;h=130" alt="" width="232" height="130" class="border-box db" data-placeholder="true" /></a>
                                        </div>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/recipes-for-one-or-two/"><span>Recipes for 1 or 2, with little to no
                                                    leftovers</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/kari-sonde/">Kari Sonde</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                double-wide-with-far-right-table-layout
                grid-top grid-bottom grid-center
                table2
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 9;
                --c-start-lg: 9;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 8;
                --c-span-lg: 8;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table2-columns-main
                  grid-top grid-bottom grid-left
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 1;
                  --c-start-md: 1;
                  --c-start-sm: 1;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 4;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --r-span-mx: 4;
                  --r-span-lg: 4;
                  --r-span-md: 4;
                  --r-span-sm: 4;
                  --dsktp-order: 1;
                " data-index="1" data-admin-id="4" data-feature-name="food-feed-3" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="4" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left no-wrap-text art-size--fullWidth">
                                <div class="card-top card-text">
                                    <div class="w-100 mw-100 overflow-hidden relative lh-0 mb-xs">
                                        <div class="dib" style="
                          width: 100%;
                          height: 100%;
                          background-color: #eee;
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/chana-masala-recipe-instant-pot-chickpeas/" class="art-link"><img style="
                              width: 232px;
                              height: 130px;
                              background-color: #eee;
                            " src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Fimg%252Ewashingtonpost%252Ecom%2Fwp-stat%2Fspacer%252Egif&amp;w=232&amp;h=130" alt="" width="232" height="130" class="border-box db" data-placeholder="true" /></a>
                                        </div>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/chana-masala-recipe-instant-pot-chickpeas/"><span>Make Instant Pot chana masala for creamy, spiced
                                                    chickpeas any night</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/becky-krystal/">Becky Krystal</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table2-columns-right
                  grid-top grid-bottom grid-right
                " style="
                  --c-start-mx: 5;
                  --c-start-lg: 5;
                  --c-start-md: 7;
                  --c-start-sm: 6;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 4;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --r-span-mx: 4;
                  --r-span-lg: 4;
                  --r-span-md: 4;
                  --r-span-sm: 4;
                  --dsktp-order: 2;
                " data-index="2" data-admin-id="5" data-feature-name="food-feed-4" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="4" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left no-wrap-text art-size--fullWidth">
                                <div class="card-top card-text">
                                    <div class="w-100 mw-100 overflow-hidden relative lh-0 mb-xs">
                                        <div class="dib" style="
                          width: 100%;
                          height: 100%;
                          background-color: #eee;
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/sheet-pan-chow-mein-recipe/" class="art-link"><img style="
                              width: 232px;
                              height: 130px;
                              background-color: #eee;
                            " src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Fimg%252Ewashingtonpost%252Ecom%2Fwp-stat%2Fspacer%252Egif&amp;w=232&amp;h=130" alt="" width="232" height="130" class="border-box db" data-placeholder="true" /></a>
                                        </div>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/food/2022/01/12/sheet-pan-chow-mein-recipe/"><span>Chow mein on a sheet pan? This adaptable recipe
                                                    hits all the right notes.</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/ann-maloney/">Ann Maloney</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                double-wide-with-far-right-table-layout
                grid-top grid-bottom grid-right
                table9
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 17;
                --c-start-lg: 1;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 4;
                --c-span-lg: 16;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table9-childStyles
                  grid-top grid-bottom grid-left grid-right
                  hide-for-print
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 0;
                  --c-start-md: 0;
                  --c-start-sm: 0;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 16;
                  --c-span-md: 12;
                  --c-span-sm: 10;
                  --c-span-xs: 1;
                  --dsktp-order: 0;
                " data-index="2" data-admin-id="3" data-feature-name="nl-voraciously" moat-id="fronts/newsletter-module" data-mx-span="4" data-lg-span="16" data-md-span="12" data-sm-span="10" data-xs-span="1">
                            <div class="ma-auto" style="max-width: 628px">
                                <div class="dib w-100" data-sc-v="5.1.1" data-sc-c="newslettersignup">
                                    <div data-sc-v="5.1.1" data-sc-c="newslettersignup">
                                        <div class="
                          flex
                          justify-center
                          align
                          self-center
                          center
                          transition-all
                          duration-400
                          ease-in-out
                          newsletter
                        " data-qa="sc-newsletter-signup">
                                            <svg aria-labelledby="wpds-ns-small-skeleton-react-aria-4-aria" role="img" height="229" width="100%" viewBox="0 0 100% 229">
                                                <title id="wpds-ns-small-skeleton-react-aria-4-aria">
                                                    Loading...
                                                </title>
                                                <rect role="presentation" x="0" y="0" width="100%" height="100%" clip-path="url(#wpds-ns-small-skeleton-react-aria-4-diff)" style="
                              fill: url(#wpds-ns-small-skeleton-react-aria-4-animated-diff);
                            "></rect>
                                                <defs>
                                                    <clipPath id="wpds-ns-small-skeleton-react-aria-4-diff">
                                                        <rect x="50%" y="0" rx="0" ry="0" width="180" height="20" transform="translate(-90 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                        <rect x="50%" y="28" rx="0" ry="0" width="166" height="93" transform="translate(-83 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                        <rect x="50%" y="129" rx="4" ry="4" width="232" height="24" transform="translate(-116 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                        <rect x="50%" y="157" rx="4" ry="4" width="232" height="14" transform="translate(-116 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                        <rect x="50%" y="175" rx="4" ry="4" width="174.00" height="14" transform="translate(-87 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                        <rect x="50%" y="197" rx="20" ry="20" width="120" height="32" transform="translate(-60 0)" data-sc-v="5.1.1" data-sc-c="stackofrects"></rect>
                                                    </clipPath>
                                                    <linearGradient id="wpds-ns-small-skeleton-react-aria-4-animated-diff">
                                                        <stop offset="0%" stop-color="#e9e9e9" stop-opacity="1"></stop>
                                                        <stop offset="50%" stop-color="#e9e9e9" stop-opacity="1"></stop>
                                                        <stop offset="100%" stop-color="#e9e9e9" stop-opacity="1"></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import axios from "axios";


export default {
    props: ["categoryNewsData","keyVal"],
    components: {
        carousel,
        // starRating,
    },
    data() {
        return {
            whishlist: 0,
        };
    },
    mounted() {
        this.orderDetail()
    },
    methods: {
          orderDetail() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'myorder',
                    uid: ev.uid,

                }
            }).then(function (response) {
                var data = response.data.data
                ev.orderDetailData = data
            })
        },
    },
};
</script>
