<template>
  <div class="hpgrid-max-width ma-auto" data-chain-name="Climate box" data-gtm-module="Climate box" style="position: relative">
                <div class="mr-sm ml-sm mr-0-ns ml-0-ns">
                    <div class="gray-darkest mb-sm label label-section-large left">
                        <a class="
                  font-xs font-bold
                  uppercase
                  letter-spacing
                  offblack
                  hover-blue
                  label-link
                " data-href="#https://www.washingtonpost.com/climate-environment/?itid=nb_climate-and-environment"><span>Climate &amp; Environment</span><span class="relative" style="margin-left: 0.125rem"><span style="position: absolute; size-to-use: normal; top: 0"><svg class="
                        content-box
                        fill-gray
                        hover-fill-blue hover-inherit
                      " width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-sc-v="5.1.1" data-sc-c="iconbase">
                                        <title data-sc-v="5.1.1" data-sc-c="iconbase">
                                            ChevronRight
                                        </title>
                                        <path d="M9.44 7.854L4.145 2.56l1.415-1.415 6 6a1 1 0 0 1 0 1.415l-6 6-1.415-1.415L9.44 7.854z" fill-rule="nonzero" data-sc-v="5.1.1" data-sc-c="iconbase"></path>
                                    </svg></span></span></a>
                    </div>
                </div>
                <div class="
              chain
              hpgrid hpgrid-max-width
              ma-auto
              mx-dsktp-order
              large-bottom-separator
              no-line-bottom
              include-dividers-tables
            ">
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                extra-wide-left-layout
                grid-top grid-bottom grid-left
                table1
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 1;
                --c-start-lg: 1;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 12;
                --c-span-lg: 12;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table1-columns-left
                  grid-top grid-bottom grid-left grid-right
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 1;
                  --c-start-md: 1;
                  --c-start-sm: 1;
                  --c-start-xs: 0;
                  --c-span-mx: 12;
                  --c-span-lg: 12;
                  --c-span-md: 12;
                  --c-span-sm: 10;
                  --c-span-xs: 1;
                  --dsktp-order: 3;
                " data-index="0" data-admin-id="3" data-feature-name="12-10a-lakesuperior" moat-id="fronts/flex-feature" data-mx-span="12" data-lg-span="12" data-md-span="12" data-sm-span="10" data-xs-span="1">
                            <div class="left wrap-text art-size--lg">
                                <div class="card-right card-art left art--right no-bottom">
                                    <div class="w-100 mw-100 overflow-hidden relative lh-0 mb-xxs">
                                        <div class="dib" style="
                          width: 100%;
                          height: 100%;
                          background-color: #eee;
                        ">
                                            <a data-href="#https://www.washingtonpost.com/magazine/2022/01/12/lake-superior-forever-chemicals/" class="art-link"><img style="
                              width: 100%;
                              height: 100%;
                              background-color: rgb(238, 238, 238);
                            " src="https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Farc-anglerfish-washpost-prod-washpost%252Es3%252Eamazonaws%252Ecom%2Fpublic%2FYY4HTTC4JAI6ZLS3KABCSIZXY4%252Ejpg&amp;w=364&amp;h=243" alt="Image" width="364" height="243" class="border-box db" srcset="
                              https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Farc-anglerfish-washpost-prod-washpost%252Es3%252Eamazonaws%252Ecom%2Fpublic%2FYY4HTTC4JAI6ZLS3KABCSIZXY4%252Ejpg&amp;w=728&amp;h=486  2x,
                              https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Farc-anglerfish-washpost-prod-washpost%252Es3%252Eamazonaws%252Ecom%2Fpublic%2FYY4HTTC4JAI6ZLS3KABCSIZXY4%252Ejpg&amp;w=1456&amp;h=972 4x
                            " /></a>
                                        </div>
                                    </div>
                                    <div class="
                        gray-dark
                        font-xxxxs
                        left
                        pb-xs
                        font--meta-text
                        lh-sm
                      ">
                                        Balancing Rock in northern Wisconsin waters of Lake
                                        Superior.
                                    </div>
                                </div>
                                <div class="card-left card-text no-bottom">
                                    <div class="gray-darkest mb-xxs label label-kicker left">
                                        <a class="
                          font-xxxxs font-bold
                          lh-sm
                          offblack
                          hover-blue
                          label-link
                        " data-href="#https://www.washingtonpost.com/magazine/2022/01/12/lake-superior-forever-chemicals/"><span>From The Magazine</span></a>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-lg font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/magazine/2022/01/12/lake-superior-forever-chemicals/"><span>Indigenous tribes around Lake Superior depend on
                                                    fishing to survive — but the fish are
                                                    contaminated</span></a>
                                        </h2>
                                    </div>
                                    <div class="
                        pb-xs
                        font-size-blurb
                        lh-fronts-tiny
                        font-light
                        gray-dark
                      " style="font-size: 0.9375rem">
                                        <span>The lake's fish contain what are known as "forever
                                            chemicals." Will the government’s recent steps to
                                            regulate this type of chemicals be enough?</span>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        <span>Story and photos by Shantal Riley</span>
                                    </div>
                                </div>
                                <div class="cb"></div>
                            </div>
                        </div>
                    </div>
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                extra-wide-left-layout
                grid-top grid-bottom grid-center
                table2
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 13;
                --c-start-lg: 13;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 4;
                --c-span-lg: 4;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table2-childStyles
                  grid-top grid-bottom grid-left grid-right
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 1;
                  --c-start-md: 0;
                  --c-start-sm: 0;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 4;
                  --c-span-md: 12;
                  --c-span-sm: 10;
                  --c-span-xs: 1;
                  --dsktp-order: 2;
                " data-index="1" data-admin-id="1" data-feature-name="12-9a-climate202" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="4" data-md-span="12" data-sm-span="10" data-xs-span="1">
                            <div class="left wrap-text">
                                <div class="card-top card-text">
                                    <div class="gray-darkest mb-xxs label label-kicker left">
                                        <a class="
                          font-xxxxs font-bold
                          dib
                          mr-xs
                          lh-sm
                          dib
                          offblack
                          hover-blue
                          label-link
                        " data-href="#https://www.washingtonpost.com/politics/2022/01/12/two-things-we-learned-senate-hearings-about-build-back-better-fed-approach-climate-change/"><span>The Climate 202</span></a><span class="dib font-xxxxs lh-sm gray-darkest"><span></span></span>
                                    </div>
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/politics/2022/01/12/two-things-we-learned-senate-hearings-about-build-back-better-fed-approach-climate-change/"><span>Two things we learned from Senate hearings about
                                                    Build Back Better and the Fed’s approach to climate
                                                    change</span></a>
                                        </h2>
                                    </div>
                                    <div class="
                        pb-xs
                        font-size-blurb
                        lh-fronts-tiny
                        font-light
                        gray-dark
                      " style="font-size: 0.9375rem">
                                        <span>We watched both hearings so you didn't have to.</span>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/maxine-joselow/">Maxine Joselow</a>
                                        and
                                        <a data-href="#https://www.washingtonpost.com/people/alexandra-ellerbeck/">Alexandra Ellerbeck</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="
                table-in-grid
                hpgrid
                hpgrid-item
                hpgrid-item--c-start
                hpgrid-item--c-spans
                hpgrid-item--r-spans
                extra-wide-left-layout
                grid-top grid-bottom grid-right
                table9
                include-dividers-features
                hide-helpers
              " style="
                --c-start-mx: 17;
                --c-start-lg: 1;
                --c-start-md: 1;
                --c-start-sm: 1;
                --c-start-xs: 1;
                --c-span-mx: 4;
                --c-span-lg: 16;
                --c-span-md: 12;
                --c-span-sm: 10;
                --c-span-xs: 1;
              ">
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table9-childStyles
                  grid-bottom grid-left grid-right
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 0;
                  --c-start-md: 0;
                  --c-start-sm: 0;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 8;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --dsktp-order: 1;
                " data-index="2" data-admin-id="2" data-feature-name="11-4a-ocean-heat" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="8" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left wrap-text">
                                <div class="card-top card-text">
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/weather/2022/01/11/ocean-heat-record-warm-climate/"><span>Ocean warmth sets record high in 2021 as a result
                                                    of greenhouse gas emissions</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/kasha-patel/">Kasha Patel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="
                  card
                  relative
                  hpgrid-item
                  hpgrid-item--c-start
                  hpgrid-item--c-spans
                  hpgrid-item--r-spans
                  table9-childStyles
                  grid-top grid-left grid-right
                " style="
                  --c-start-mx: 1;
                  --c-start-lg: 0;
                  --c-start-md: 0;
                  --c-start-sm: 0;
                  --c-start-xs: 0;
                  --c-span-mx: 4;
                  --c-span-lg: 8;
                  --c-span-md: 6;
                  --c-span-sm: 5;
                  --c-span-xs: 1;
                  --dsktp-order: 0;
                " data-index="3" data-admin-id="4" data-feature-name="12-12p-maine" moat-id="fronts/flex-feature" data-mx-span="4" data-lg-span="8" data-md-span="6" data-sm-span="5" data-xs-span="1">
                            <div class="left wrap-text">
                                <div class="card-top card-text">
                                    <div class="headline relative gray-darkest pb-xs">
                                        <h2 class="
                          font--headline font-size-sm font-bold
                          left
                          relative
                        ">
                                            <a data-href="#https://www.washingtonpost.com/weather/2022/01/12/gulf-maine-record-warm-2021/"><span>Gulf of Maine waters spiked to record warm levels
                                                    in fall 2021</span></a>
                                        </h2>
                                    </div>
                                    <div class="byline gray-dark font-xxxxs pb-xs">
                                        By
                                        <a data-href="#https://www.washingtonpost.com/people/kasha-patel/">Kasha Patel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import axios from "axios";


export default {
    props: ["categoryNewsData","keyVal"],
    components: {
        carousel,
        // starRating,
    },
    data() {
        return {
            whishlist: 0,
        };
    },
    mounted() {
        this.orderDetail()
    },
    methods: {
          orderDetail() {

            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'myorder',
                    uid: ev.uid,

                }
            }).then(function (response) {
                var data = response.data.data
                ev.orderDetailData = data
            })
        },
    },
};
</script>
