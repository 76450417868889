<template>
<body>
    <div id="__next">
        <div class="flex flex-column" style="min-height:100vh">
            <main class="flex-grow-1 flex-shrink-0">
                <main class="overflow-hidden">
                    <div class="relative block-element">
                        <div class="w-100 bg-white undefined bb center items-center justify-center flex bc-gray-lighter main-block b bb " style="height:60px">
                            <router-link to="/">
                                <img width="200" src="../assets/assembler/images/logo-dark.webp" />
                            </router-link>
                        </div>
                    </div>
                    <div class="relative block-element">
                        <div class="main-block pa-sm pt-md pt-xl-ns pb-xl relative">
                            <div class="relative block-element mb-xs mb-sm-ns">
                                <div class="relative">
                                    <div class="center font--headline font-xl-ns font-md" data-qa="header-text">
                                        <p><b>Choose your plan.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="relative block-element mb-sm mb-lg-ns">
                                <div class="relative">
                                    <div class="franklin font-xxs font-sm-ns center" data-qa="secondary-header-text">
                                        <p>You can <b>cancel anytime.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="center mb-sm mb-md-ns"></div>
                            <div class="contaner">
                                <div class="row">
                                    <div class="col-lg-6 offset-lg-3 row">
                                        <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="flex-1
          shadow-light
          b
          bc-gray-light
          brad-4
          center
          ml-sm-ns
          mr-sm-ns
          mb-0-ns
          b
          pointer
          flex
          flex-column
          pl-sm
          pr-sm
          pt-md
          pb-md
          w-400-ns
          mw-100
          relative
           mb-sm"
           >
                                                <div class="font--headline font-md2 mb-sm bold">All-Access Digital</div>
                                                <div class="flex flex-column">
                                                    <div class="order-1-ns mb-sm-ns">
                                                        <div class="relative block-element">
                                                            <div class="w-100">
                                                                <button class="btn btn-theme" @click="orderGenerate(1)">Get one year for ₹999 </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="order-2-ns">
                                                        <div>
                                                            <ul class="list-unstyled left pl-0 mt-0 mb-sm">
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p><b>Unlimited access</b> on the web and in our apps</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p><b>24/7</b> live news updates</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>Most comprehensive <b>political and international coverage</b></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>Groundbreaking <b>interactive stories</b></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element visiblity-hidden">
                                                                    <li class="gray-darker franklin flex items-start"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>Groundbreaking <b>interactive stories</b></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="flex-1
          shadow-light
          b
          bc-gray-light
          brad-4
          center
          ml-sm-ns
          mr-sm-ns
          mb-0-ns
          b
          pointer
          flex
          flex-column
          pl-sm
          pr-sm
          pt-md
          pb-md
          w-400-ns
          mw-100
          relative
           mb-0">
                                                <div class="font--headline font-md2 mb-sm bold">Premium Digital</div>
                                                <div class="flex flex-column">
                                                    <div class="order-1-ns mb-sm-ns">
                                                        <div class="relative block-element">
                                                            <div class="w-100">
                                                                <button class="btn btn-theme" @click="orderGenerate(2)">Get one year for ₹1,999 </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="order-2-ns">
                                                        <div>
                                                            <ul class="list-unstyled left pl-0 mt-0 mb-sm">
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>Every <b>All-Access Digita</b>l feature</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <div class="gray-darkest light font-xxs franklin center mb-sm mt-sm flex items-center"><span class="flex-1 b bt"></span><span class="flex-0 nowrap ml-sm mr-sm">PLUS</span><span class="flex-1 b bt"></span></div>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>A <b>bonus subscription</b> to share</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start" style="margin-bottom:12px"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p>Monthly <b>30-day digital passes</b> to share</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div class="relative block-element">
                                                                    <li class="gray-darker franklin flex items-start"><svg class="content-box fill-green pr-xs" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="min-width:16px;padding-top:2px" data-sc-v="5.0.1" data-sc-c="icon">
                                                                            <title data-sc-v="5.0.1" data-sc-c="icon">check</title>
                                                                            <path d="M6.565 10.573L2.604 6.612 1.19 8.026l4.754 4.755a1 1 0 0 0 1.491-.086l7.505-9.466-1.567-1.242-6.808 8.586z" fill-rule="nonzero" data-sc-v="5.0.1" data-sc-c="icon"></path>
                                                                        </svg>
                                                                        <div class="flex-grow-1">
                                                                            <div class="relative">
                                                                                <div class="w-100 db" style="min-height:20px">
                                                                                    <p><b>Exclusive eBooks</b> written by our journalists</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="relative block-element">
                        <footer class="w-100 b bt font-xxxxs font-xxxs-ns bc-gray-lighter flex items-center justify-center main-block white">
                            <ul class="flex center items-center justify-center-ns list list-none flex-column flex-ns-row gray-dark ma-0 pa-0 pt-xxs pb-xxs pb-0-ns">
                                <li class="order-3-ns flex justify-center items-center"><a class="dib gray-dark pl-md pr-md lh-xl pt-xxs pb-xxs " target="_blank" href="#" rel="noopener noreferrer" data-qa="footer-privacy-policy-link">Privacy Policy</a>
                                    <div class="b br bc-gray-lighter h-xxs "></div><a class="dib gray-dark pl-md pr-md lh-xl pt-xxs pb-xxs" href="#">Need Help?</a>
                                </li>
                            </ul>
                        </footer>
                    </div>
                </main>
            </main>
        </div>
    </div>

</body>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header";
import Footer from "@/components/footer";
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            FinalTotalAmount: 0,
            add_id: 0,
            cgstLess: 0,
            sgstLess: 0,
            withTextAmount: 0,
            withoutTextAmount: 0,
            totalwithoutgst: 0,
            ttotalwithoutgst: 0,
            billing_id: 0,
            paymentMethod: 1,
            couponCode: '',
            getCounts: '',
            myCouponMoney: localStorage.getItem('coupon') ? localStorage.getItem('coupon') : 0,
            isHas: false,
            isAdd: 0,
            shipping: 0,
            min: 0,
            dashboard_box: "step-1",
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            uid: localStorage.getItem("user"),
            userData: [],
            cartCount: [],
            amount: 100,
            addressDatas: [],
            totalAmount: 1000,
            selectSubscription: 1,
            paymenttype: 'ppd',
            awbNumber: '',
            selectedAddress: [],
            getShippingAddressData: [],
            getBillingAddressData: [],
            selectedBillingAddress: [],
            userAddress: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: 'Shipping',
                shippingBilling: false
            },
            userAddressBilling: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: '',
            }

        };
    },
    watch: {},
    mounted() {
        this.includeStripe('checkout.razorpay.com/v1/checkout.js');
        this.uid = localStorage.getItem("user");
    },
    methods: {
                orderGenerate(type) {
            var ev = this
            var user_session = localStorage.getItem("user");
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var amount = 0
            var subscription = ''
            if(type==1){
                   amount = 999
                   subscription = 'Digital'
               }else{
                   amount = 1999
                   subscription = 'Premium'
               }
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "order",
                    uid: uid,
                    total: amount,
                    subscription: subscription
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    localStorage.setItem('oid', data.oid)
                        ev.makePayment(type)
                }
            });
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = 'https://' + URL;
            if (callback) {
                object.addEventListener('load', function (e) {
                    callback(null, e);
                }, false);
            }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },
        makePayment: function (type) {
           if(type) {
                var ev = this
                var amount = 0
               if(type==1){
                   amount = 999
               }else{
                   amount = 1999
               }
                var rzp1 = new Razorpay({
                    key: 'rzp_test_MBSCGzeRjYUhaT',
                    amount: amount * 100,
                    currency: "INR",
                    "handler": function (response) {
                        ev.pay_id = response.razorpay_payment_id
                        localStorage.setItem('successData', response)
                        ev.updatePayment(response);
                    },
                    "ondismiss": function () {
                        window.location.reload();
                    },
                    prefill: {},
                    notes: {
                        // address: this.address, //this.$store.state.profile.address
                    },
                    theme: {
                        color: "#00FFFF"
                    },
                    //order_id: 1,
                    redirect: false
                });
                rzp1.open();
            }

        },
                updatePayment(payment_detail) {
            var self = this;
            var oid = localStorage.getItem('oid');
            var uid = localStorage.getItem('user');
            axios.post(this.$root.URL_ROOT + "api.php", {
                type: 'payment_success',
                uid: uid,
                oid: oid,
                payment_detail: payment_detail,
            }).then(function (response) {
                var data = response.data
                if (data.status == 'success') {
                    self.$router.push({
                            name: 'success'
                        });
                }
            });
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    },
};
</script>
